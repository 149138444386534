<template>
    <div class="paddingmobile">
            <div class="d-flex justify-center align-center pt-4">
                <img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
            </div>
        <v-container>
            <v-overlay
          :opacity="0.3"
          :value="loading"
          color="lightgrey"
        >
          <v-progress-circular indeterminate size="64" color="primary">
          </v-progress-circular>
        </v-overlay>
            <v-row class="dense">
                <v-col cols="12" xs="12" sm="12" class="">
                    <FarmCard>
                        <!-- <template v-slot:header>Title</template> -->
                        <v-row class="px-1" dense>
                            <v-col cols="12">
                                <div class="title text-center pb-5">{{ $t("welcome") }} <b>{{userFirstName}}</b></div>
                                <div class="title  font-weight-bold text-center pb-5" v-if="isStaging()">STAGING DATABASE</div>
                                <SearchField :placeholder="$t('search')+ '...'" v-model="search" background="lightgrey" />
                            </v-col>
                        </v-row>
                    </FarmCard>
                </v-col>
                <v-col cols="12" sm="6">
                    <div class="title pl-4">{{ $t("findabusiness") }}</div>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex" :class="$vuetify.breakpoint.xsOnly ? 'justify-space-between' : 'justify-end'">
                    <div v-if="!$vuetify.breakpoint.xsOnly" class="title pr-4 removetexttransform pt-1">{{ $t("sortby") }}</div>
                    <v-select
                        :items="sortByItems"
                        label="Sort By"
                        v-model="sortBy"
                        item-text="name"
                        item-value="value"
                        v-on:change="initialise()"
                        solo
                        background-color="lightgrey"
                        class="rounded-of"
                        style="max-width: 10em;"
                    ></v-select>
                    <FilterDrawer v-on:filterSubmit="getFilteredOrg" />
                </v-col>
                <div v-if="stores.length <= 0" class="subtitle-4 black--text pl-6">{{ $t("noresult") }}</div>
                <v-col v-for="(store, index) in stores" :key="index" cols="12" xs="12" sm="6" class="">
                    <StoreCard :datadown="store">
                        <template v-slot:toptitle>{{ store.name }}</template>
                        <!-- <template v-slot:icon-name>{{ store.icon }}</template> -->
                        <template v-slot:storestatus>{{ store.businessStatus }}</template>
                    </StoreCard>
                </v-col>
                <v-col cols="12" class="d-flex">
                    <v-btn depressed v-if="stores.length >= 30" @click="loadMore()" class="primary rounded-of mx-auto">
                        Load More
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "FindStoreView",
    data: () => ({
        loading: false,
        search: "",
        storeCount: 30,
        storeKey: 0,
        storeOffset: 0,
        isFiltered: false,
        businessType: "",
        sortBy: "businessSquareImageID", //change here
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
        items: [
            { name: "Item 1", v: "value 1" },
            { name: "Item 2", v: "value 2" },
            { name: "Item 3", v: "value 3" },
            { name: "Item 4", v: "value 4" },
        ],
        sortByItems: [
            { name: 'Random', value: 'random'},
            { name: 'Date Joined', value: 'createdDateTime'},
            { name: 'A-Z', value: 'name'},
            { name: 'Business Type', value: 'type'}
        ],
        stores: []
    }),
    components: {
        FarmCard: () => import("@/components/card/FarmCard.vue"),
        StoreCard: () => import("@/components/card/StoreCard.vue"),
        FilterDrawer: () => import("@/components/filter/StoreFilter.vue"),
    },
    computed: {
        ...mapGetters({
            GET_showFilterDrawer: "GET_showFilterDrawer",
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
        computedStores(){
            let stores = this.stores;
            const options = {
                includeScore: true,
                threshold: 0.6,
                keys: ['name']
            };
            if(this.search){
                const fuse = new this.$fuse(stores, options)
                stores = fuse.search(this.search).map(x=>x.item)
                //console.log(JSON.stringify(stores, null, 2))
            }
            return stores
        },
        computedSearch(){
            var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
            if(this.search.length > 1 && onlyLetters.test(this.search)){
                return this.search.trim() + "*"
            }else{
                return ""
            }
        }
    },
    methods: {
        ...mapActions({
            ACT_showFilterDrawer: "ACT_showFilterDrawer",
        }),
        updateShowFilterDrawer() {
            this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
        },
        async getOrgs(){
            this.isFiltered = false;

            let sortOrder = "desc";

            this.stores = [];

            this.storeOffset = 0;
            this.storeCount = 30;

            if(this.sortBy === "name"){
                sortOrder = "asc";
            }
            return new Promise((resolve, reject) => {

                this.REDIS_searchFor("org", this.storeOffset, this.storeCount, this.sortBy, sortOrder, `${this.computedSearch} @businessStatus:Occupied @deleted:{false}`)
                .then((result) => {
                    this.stores = result.data.documents;
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        async initialise() {
            this.loading = true;
            this.getUser();
            await this.getOrgs()
            this.loading = false;
            //console.log(JSON.stringify(this.stores, null, 2))
            //console.log(this.stores[1].name)
        },
        async getFilteredOrg(businessType){
            this.loading = true;
            this.isFiltered = true;
            this.businessType = businessType;
            let sortOrder = "desc";

            if(this.sortBy === "name"){
              sortOrder = "asc";
            }
            // console.log("Distance: " + distance)
            // console.log("Business Type: " + businessType)
            //REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query)
                this.REDIS_searchFor("org", this.storeOffset, this.storeCount, this.sortBy, sortOrder, `${this.computedSearch} @businessStatus:Occupied @type:${this.businessType} @deleted:{false}`)
                .then((result) => {
                    this.stores = result.data.documents;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading = false;
                });
        },
        getUser(){
            if(this.GET_currentUserProfile){
                this.userFirstName = this.GET_currentUserProfile.firstName;
            }else if(localStorage.userToken){
                // console.log("No user profile found, using local storage")
                //console.log(localStorage.userToken)
                this.userFirstName = this.MIX_tokenToUser(localStorage.userToken).given_name;
            }else{
                this.userFirstName = "Guest";
            }
        },
        loadMore(){
            this.storeOffset = this.storeOffset + this.storeCount;
            let sortOrder = "desc";

            if(this.sortBy === "name"){
              sortOrder = "asc";
            }
            if(this.isFiltered){
                this.REDIS_searchFor("org", this.storeOffset, this.storeCount, this.sortBy, sortOrder, `${this.computedSearch} @businessStatus:Occupied @type:${this.businessType} @deleted:{false}`)
                .then((result) => {
                    for(let store of result.data.documents){
                        this.stores.push(store);
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
            }else{
                this.REDIS_searchFor("org", this.storeOffset, this.storeCount, this.sortBy, sortOrder, `${this.computedSearch} @businessStatus:Occupied @deleted:{false}`)
                .then((result) => {
                    for(let store of result.data.documents){
                        this.stores.push(store);
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
            }
        },
      isStaging(){
          // console.log(process.env.VUE_APP_SWANSBIDSERVER)
          if(process.env.VUE_APP_SWANSBIDSERVER === 'https://swansea-bid-server-staging-vatinaqa3a-nw.a.run.app/api/v1/'){
              return true;
          }else{
              return false;
          }
      }
    },
    watch:{
        search:{
            handler: function(){
                this.initialise();
            },
            deep: true
        }
    },
    mounted(){
        // const collection = "org";
        // const entityId = "01GTEMK1R88QM1PM3CT5W0ZSZ1";
        // console.log(this.REDIS_read(collection, entityId));
    },
    created() {
        this.initialise();
    },
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}
.v-carousel__controls{
    margin-top: 200px !important;
}
</style>
