<template>
    <v-navigation-drawer app permanent clipped stateless
        class="primary rounded-of-right-xl pt-7 navbarside foreground-high" width="160">
        <v-row class="">
            <v-col cols="12" class="d-flex align-center" style="margin-bottom: -1em;" v-if="$vuetify.breakpoint.lgAndUp">
                <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo.png" contain height="80px" />
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/ active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text menubutton flexcol border-none removetexttransform"
                    block depressed><v-icon class="py-2 white--text sidebaricon">icons8-shop-location</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("findabusiness") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol" v-if="isSwanseaResident()">
                <template>
                    <div class="text-center">
                        <v-menu transition="slide-x-transition" :close-on-content-click="false" :nudge-width="10" offset-x
                            rounded="0" class="navbarmenu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on"
                                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                                    block depressed x-large><v-icon
                                        class="py-2 white--text sidebaricon">icons8-sale-price-tag</v-icon>
                                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("offers") }}</div>
                                </v-btn>
                            </template>
                            <v-card class="primary navbarcard foreground-high" style="overflow: hidden !important;">

                                <v-btn to=/offers active-class="secondary activepage"
                                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                                    block depressed x-large><v-icon
                                        class="py-2 white--text sidebaricon">icons8-sale-price-tag</v-icon>
                                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("latestoffers") }}</div>
                                </v-btn>

                                <v-btn to=/my-offers active-class="secondary activepage"
                                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                                    block depressed x-large><v-icon
                                        class="py-2 white--text sidebaricon">icons8-sale-price-tag</v-icon>
                                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("myoffers") }}</div>
                                </v-btn>
                            </v-card>
                        </v-menu>
                    </div>
                </template>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol" v-if="isSwanseaResident()">
                <v-btn to=/parking active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-parking</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("parking") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/dining-out active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-cutlery</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("diningout") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/whats-on active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-calendar-17</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("whatson") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <template>
                    <div class="text-center">
                        <v-menu transition="slide-x-transition" :close-on-content-click="false" :nudge-width="10" offset-x
                            rounded="0" class="navbarmenu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on"
                                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                                    block depressed x-large><v-icon
                                        class="py-2 white--text sidebaricon">icons8-quality</v-icon>
                                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("cardlist") }}</div>
                                </v-btn>
                            </template>
                            <v-card class="primary navbarcard foreground-high" style="overflow: hidden !important;">
                                <v-btn href="https://mi-rewards.com"
                                    active-class="secondary activepage"
                                    class="text-body-2 font-weight-bold primary white--text menubutton flexcol removetexttransform"
                                    block depressed x-large><v-icon
                                        class="py-2 white--text sidebaricon">icons8-quality</v-icon>
                                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("loyaltycard") }}</div>
                                </v-btn>
                                <v-btn href="https://townandcitygiftcards.com/product/swansea-gift-card/"
                                    active-class="secondary activepage"
                                    class="text-body-2 font-weight-bold primary white--text menubutton flexcol removetexttransform"
                                    block depressed x-large><v-icon
                                        class="py-2 white--text sidebaricon">icons8-gift-card</v-icon>
                                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("giftcard") }}</div>
                                </v-btn>
                            </v-card>
                        </v-menu>
                    </div>
                </template>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/defibrillators active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-heart-with-pulse</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("defibs") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12"
                v-if="isAdmin()"
                class="text-center navbarbuttoncol">
                <v-btn @click="switchtoAdmin()" active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-analytics</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("admin") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center" v-if="!GET_authenticated">
                <v-btn to="/login"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-logout</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("login") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center" v-if="GET_authenticated">
                <v-btn @click="logoutDialog = true"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-logout</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("logout") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center px-5" v-if="GET_currentUserProfile">
                <!-- <v-btn @click="MIX_go('/home')" class="primary text-body-2 font-weight-bold white--text removetexttransform"
                    block x-large depressed>
                    <div class="pb-2 full-width text-center">{{ $t("profile") }}</div>
                    <v-icon class="pb-2 white--text sidebaricon">icons8-male-user</v-icon>
                </v-btn> -->
                <ProfileDialog :userProfile="GET_currentUserProfile" :dialogTitle="$t('Myprofile')" />
            </v-col>
            <v-col cols="12" class="text-center">
                <div class="caption white--text text--darken-3 full-width text-center mt-2">{{ $t("version") }} {{ appVersion }}</div>
            </v-col>
        </v-row>
      <!--Log out confirm dialog-->
      <v-dialog v-model="logoutDialog" persistent max-width="300px">
        <v-card class="white pa-7 rounded-of">
          <v-row class="white">
            <v-col cols="12">
              <div class="title black--text text-center px-5">{{ $t("logoutconfirm") }}</div>
            </v-col>
          </v-row>
          <v-card-actions class="justify-center">
            <v-btn color="subtitle-1 px-6 grey rounded-of removetexttransform" depressed @click="logoutDialog = false">
              {{ $t("back") }}
            </v-btn>
            <v-btn color="subtitle-1 px-6 primary rounded-of removetexttransform" depressed
                   @click="logout()">
              {{ $t("logout") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
//import { version } from "../../package.json";
export default {
    name: "SideNav",
    data() {
      return {
        logoutDialog: false
      }
    },
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
            GET_authenticated: 'GET_authenticated',
        }),
        appVersion() {
            const version = require('../../../package.json').version
            return version;
        },

    },
    components: {
        ProfileDialog: () => import("@/components/card/ProfileDialog.vue"),
    },
    methods: {
        ...mapActions({
            ACT_authenticated: "ACT_authenticated",
            ACT_isAdmin: "ACT_isAdmin",
        }),
        async logout() {
            // this.ACT_authenticated(false);
            // this.ACT_isAdmin(false);
            // this.$router.push("/");
            await localStorage.removeItem('userToken');
            await localStorage.removeItem('vuex');
            await this.FIREBASE_logout();
            this.$router.push("/");
            location.reload();
        },
        switchtoAdmin() {
            this.ACT_authenticated(false);
            this.ACT_isAdmin(true);
            this.$router.push("/admin/dashboard");
        },
        isAdmin(){
            if(this.GET_currentUserProfile != null){
                if(this.GET_currentUserProfile.level === 'shopadmin' || this.GET_currentUserProfile.level === 'superadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
        isSwanseaResident(){
            if(this.GET_currentUserProfile != null){
                const regex = /^(SA1 |SA2 |SA3 |SA4 |SA5 |SA6 |SA7 |SA8 |SA18|SA99)/;
                return regex.test(this.GET_currentUserProfile.postcode);
            }else if(localStorage.userToken){
                return true;
            }else{
                return false;
            }
        },
    },
};
</script>

<style scoped>
.menubutton {
    min-height: 20px !important;
    height: 65px !important;
    border-radius: 0px !important;
}

.navbarbuttoncol {
    padding-bottom: 0px !important;
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
}

.menupanel {
    padding: 1em !important;
    max-height: none !important;
}

.sidebaricon {
    font-size: 2.2em;
}

.activepage {
    background-color: white !important;

}

.activepage .sidebaricon {
    color: #CC0200 !important;
}

.activepage .sidebarlabel {
    color: #CC0200 !important;
}

.v-menu__content {
    box-shadow: none !important;
}

.navbarcard {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
}</style>