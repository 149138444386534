<template>
  <div class="paddingmobile">
    <div class="d-flex justify-center align-center pt-4">
      <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon"/>
    </div>
    <div class="px-10">
      <v-row>
        <v-col cols="6">
          <div class="title font-weight-black primary--text">{{ $t("mybusiness") }}</div>
        </v-col>
      </v-row>
      <v-row v-if="store.name === ''">
        <v-col>
          <div class="title font-weight-black primary--text">There is an error with your organisation, please contact
            Swansea Bid
          </div>
        </v-col>
      </v-row>
      <v-row v-if="store.name !== ''">
        <v-overlay :opacity="1" :value="loading" color="white">
          <v-progress-circular indeterminate size="64" color="primary">
          </v-progress-circular>
        </v-overlay>
        <v-col cols="12" xs="12" sm="4" class="spacedcol">
          <v-row>
            <v-col cols="12" class="d-flex flex-column align-center">
              <v-img height="22vh" width="22vh"
                     class="rounded-of dropshadow" :src='squareStoreIMG'></v-img>
            </v-col>
            <v-col cols="12" style="margin-top: -1em;">
              <div class="text-center removetexttransform" style="font-size:26px; ">{{ storeTitle }}
              </div>
              <div class="subtitle-2 text-center removetexttransform">{{ $t("bidexclusivemember") }}</div>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center" style="margin-top: -1em;">
              <v-btn @click="copyToClipboard()"
                     class="lightgrey rounded-of text-body-2 font-weight-bold white--text removetexttransform mr-3"
                     height="100%" depressed>
                <v-icon class="primary--text pr-1" style="font-size:15px;">icons8-link</v-icon>
                <div class="subtitle-2 text-center black--text py-1">{{ $t("copylink") }}</div>
              </v-btn>
              <!-- <v-btn class="primary rounded-of text-body-2 font-weight-bold white--text removetexttransform"
                  height="100%" depressed>
                  <div class="subtitle-2 text-center" style="padding:0.5em;">{{ $t("editmyprofile") }}</div>
              </v-btn> -->
            </v-col>
            <v-col cols="12" class="lightgrey rounded-of">
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-4 mb-2">
                  <EditOpenTimes :datadown="store"/>
                </v-col>
                <v-col cols="6" class="d-flex align-center pl-8">
                  <div class="subtitle-2 text-left removetexttransform">{{ $t("yourbidnumber") }}</div>
                </v-col>
                <v-col cols="6">
                  <div class="subtitle-2 white rounded-of text-left removetexttransform py-2 pl-4">{{
                      store.businessBidNo
                    }}
                  </div>
                </v-col>
                <v-col cols="6" class="d-flex align-center pl-8">
                  <div class="subtitle-2 text-left removetexttransform">{{ $t("offersclaimed") }}</div>
                </v-col>
                <v-col cols="6">
                  <div class="subtitle-2 white rounded-of text-left removetexttransform py-2 pl-4">{{
                      offersClaimed
                    }}
                  </div>
                </v-col>
                <v-col cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                  <div class="subtitle-2 text-left removetexttransform pl-5"><b>What it means to be a
                    Bid Member Exclusive:</b><br>Being a Swansea Business Improvement District (BID) member means
                    joining a community of vibrant businesses with a common goal: making Swansea a thriving and
                    prosperous City centre. <br><br>As a Swansea BID member, you are essential to our vision for the
                    city centre's exciting growth and success. By pooling our resources, ideas, and expertise, we create
                    a unified voice that amplifies our impact and brings about positive change. One of the most
                    significant benefits of being a Swansea BID member is having a say in shaping the future of our
                    beloved city. You gain the opportunity to actively participate in decision-making processes,
                    allowing your voice to be heard on matters that directly affect your business and the local
                    community. Through regular meetings, consultations, and surveys, we ensure that your opinions and
                    insights are taken into account when planning and implementing projects. <br><br>Collaboration lies
                    at the heart of the Swansea BID. By joining forces with fellow members, you unlock endless
                    possibilities for networking and forming valuable connections. We organise various events,
                    workshops, and social gatherings throughout the year, providing you with a platform to engage with
                    like-minded entrepreneurs, share best practices, and forge long-lasting business relationships.
                    These connections can lead to exciting collaborations, partnerships, and new opportunities for
                    growth. Swansea BID is dedicated to helping drive footfall and boosting economic vitality in our
                    City centre. Through targeted marketing campaigns, promotional activities, and events, we aim to
                    attract visitors, shoppers, and tourists to Swansea, enhancing the visibility and profitability of
                    our BID Levy-paying members.<br><br> As a member, you benefit from increased exposure and marketing
                    support, helping you reach a wider audience and generate more footfall for your establishment. We
                    also prioritise enhancing the overall experience for everyone who visits Swansea City centre. By
                    investing in initiatives such as improved infrastructure, cleanliness, and safety measures, we
                    create an inviting and pleasant environment that encourages people to spend more time exploring our
                    vibrant streets. Together, we can make Swansea City centre an even more attractive destination,
                    attracting both locals and visitors alike. As a Swansea BID member, you gain access to a range of
                    exclusive benefits and discounts designed to support and reward your commitment to the City centre.
                    From preferential rates on services and utilities to training opportunities and business support, we
                    strive to provide valuable resources that help your business thrive.<br><br>In summary, being a
                    Swansea BID member means becoming an active contributor to the growth and development of our City
                    centre. It means having a say, forming connections, and benefiting from collective efforts to drive
                    prosperity and success. Together, we can make Swansea an even better place to live, work, shop,
                    stay, study and do business.
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="8">
          <v-row class="lightgrey rounded-of">
<!--            <v-col cols="12" class="">-->
<!--              <v-img max-height="22vh" max-width="100%"-->
<!--                     class="rounded-of dropshadow" :src='storeIMG'></v-img>-->
<!--            </v-col>-->
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class="text-center removetexttransform pl-5">{{ $t("companyname") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.name" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class="text-center removetexttransform pl-5">{{ $t("businesssector") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="pb-0">
              <!-- <TextField v-model="form.type" label="Business Sector" background="lightgrey" makedense  /> -->
              <v-select v-model="store.type" :items=storetypes background-color="white" solo clearable dense
                        class="rounded-lg" label="Business Sector"/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class="text-center removetexttransform pl-5">{{ $t("businesstags") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="pb-0">
              <!-- <TextField v-model="form.type" label="Business Sector" background="lightgrey" makedense  /> -->
              <v-select v-model="store.businessTags" :items=businessTags background-color="white" item-text="label" item-value="value" solo clearable dense multiple
                        class="rounded-lg" label="Hospitality Tags"/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("address1") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.businessAddressLine1" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("address2") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.businessAddressLine2" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("town") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.businessTown" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("county") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.businessCounty" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("postcode") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.businessPostcode" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("email") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.businessEmail" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("phonenumber") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="storefield">
              <TextField v-model.trim="store.businessTelephone" background="white" makedense/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("businessrates") }}</div>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="8" class="storefield">
                <TextField v-model="store.businessratenum" background="white" makedense />
            </v-col> -->
            <!-- <v-col cols="12" xs="12" sm="8">
                <v-autocomplete v-model="store.businessratenum" :items="['1348192', '2462543']" chips dense filled solo hide-details hide-no-data hide-selected multiple single-line class="rounded-lg"></v-autocomplete>
            </v-col> -->
            <v-col cols="12" xs="12" sm="8">
              <BusinessRef v-model="store.businessRatesRefNo"></BusinessRef>
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">
              <div class=" text-center removetexttransform pl-5">{{ $t("businessimagesquare") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8">
              <div class="subtitle black--text text-center">Please ensure the image is clear before uploading</div>
              <FileUpload
                  accept="image/*"
                  label="Select file"
                  buttonClass="primary"
                  progressClass="pa-2"
                  folderLocation="orgImages"
                  :fileName="this.squareImageID"
                  :selectedItem="testitem1"
                  :compressionMaxSizeMB="1"
                  :compressionmaxWidthOrHeight="1920"
                  :private="false"
                  :isProfile="false"
                  v-on:update="uploadSquareImage"
              />
            </v-col>
<!--            <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield">-->
<!--              <div class=" text-center removetexttransform pl-5">{{ $t("businessimage") }}</div>-->
<!--            </v-col>-->
<!--            <v-col cols="12" xs="12" sm="8">-->
<!--              <div class="subtitle black&#45;&#45;text text-center">Please ensure the image is clear before uploading</div>-->
<!--              <FileUpload-->
<!--                  accept="image/*"-->
<!--                  label="Select file"-->
<!--                  buttonClass="primary"-->
<!--                  progressClass="pa-2"-->
<!--                  folderLocation="orgImages"-->
<!--                  :fileName="this.imageID"-->
<!--                  :selectedItem="testitem"-->
<!--                  :compressionMaxSizeMB="1"-->
<!--                  :compressionmaxWidthOrHeight="1920"-->
<!--                  :private="false"-->
<!--                  :isProfile="false"-->
<!--                  v-on:update="uploadImage"-->
<!--              />-->
<!--            </v-col>-->
            <!-- TODO: Add Languages to DB -->
            <!-- <v-col cols="12" xs="12" sm="4" class="d-flex align-center justify-start storefield"
                style="margin-top:-1em;">
                <div class=" text-center removetexttransform pl-5">{{ $t("language") }}</div>
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="d-flex" style="margin-top:-1em;">
                <v-checkbox v-model="store.lang" label="EN" value="EN" class="pr-5"></v-checkbox>
                <v-checkbox v-model="store.lang" label="CY" value="CY"></v-checkbox>
            </v-col> -->
            <v-col cols="12" class="d-flex pt-2">
              <!-- <v-btn
                  class="primary rounded-of text-body-2 font-weight-bold white--text removetexttransform filtersubmitbutton"
                  depressed>
                  <div class="heading-1 text-center">{{ $t("changepassword") }}</div>
              </v-btn> -->
              <v-spacer/>
              <v-btn @click="submitEditedorg()"
                     class="primary rounded-of text-body-2 font-weight-bold white--text removetexttransform filtersubmitbutton"
                     depressed>
                <div class="heading-1 text-center">{{ $t("submit") }}</div>
              </v-btn>
            </v-col>

            <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
              <div class="subtitle-2 text-left removetexttransform pl-5"><b>What it means to be a
                Bid Member Exclusive:</b><br>Being a Swansea Business Improvement District (BID) member means
                joining a community of vibrant businesses with a common goal: making Swansea a thriving and
                prosperous City centre. <br><br>As a Swansea BID member, you are essential to our vision for the
                city centre's exciting growth and success. By pooling our resources, ideas, and expertise, we create
                a unified voice that amplifies our impact and brings about positive change. One of the most
                significant benefits of being a Swansea BID member is having a say in shaping the future of our
                beloved city. You gain the opportunity to actively participate in decision-making processes,
                allowing your voice to be heard on matters that directly affect your business and the local
                community. Through regular meetings, consultations, and surveys, we ensure that your opinions and
                insights are taken into account when planning and implementing projects. <br><br>Collaboration lies
                at the heart of the Swansea BID. By joining forces with fellow members, you unlock endless
                possibilities for networking and forming valuable connections. We organise various events,
                workshops, and social gatherings throughout the year, providing you with a platform to engage with
                like-minded entrepreneurs, share best practices, and forge long-lasting business relationships.
                These connections can lead to exciting collaborations, partnerships, and new opportunities for
                growth. Swansea BID is dedicated to helping drive footfall and boosting economic vitality in our
                City centre. Through targeted marketing campaigns, promotional activities, and events, we aim to
                attract visitors, shoppers, and tourists to Swansea, enhancing the visibility and profitability of
                our BID Levy-paying members.<br><br> As a member, you benefit from increased exposure and marketing
                support, helping you reach a wider audience and generate more footfall for your establishment. We
                also prioritise enhancing the overall experience for everyone who visits Swansea City centre. By
                investing in initiatives such as improved infrastructure, cleanliness, and safety measures, we
                create an inviting and pleasant environment that encourages people to spend more time exploring our
                vibrant streets. Together, we can make Swansea City centre an even more attractive destination,
                attracting both locals and visitors alike. As a Swansea BID member, you gain access to a range of
                exclusive benefits and discounts designed to support and reward your commitment to the City centre.
                From preferential rates on services and utilities to training opportunities and business support, we
                strive to provide valuable resources that help your business thrive.<br><br>In summary, being a
                Swansea BID member means becoming an active contributor to the growth and development of our City
                centre. It means having a say, forming connections, and benefiting from collective efforts to drive
                prosperity and success. Together, we can make Swansea an even better place to live, work, shop,
                stay, study and do business.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BusinessRef from '@/components/form/BusinessRef.vue'
import {mapGetters} from 'vuex';
import {getDownloadURL, ref} from '@firebase/storage';

export default {
  name: "AdminMyStoreView",
  data: () => ({
    loading: false,
    search: "",
    storeTitle: "",
    testitem: {},
    testitem1: {},
    imageID: "",
    squareImageID: "",
    storeIMG: "",
    offersClaimed: 0,
    squareStoreIMG: "",
    storetypes: ["public", "hotel", "finance", "legal", "hospitality", "health and beauty", "education", "professional", "entertainment", "retail", "other"],
    businessTags: [
      {label: "Vegetarian", value: "vegetarian"},
      {label: "Vegan", value: "vegan"},
      {label: "Celiac (Gluten Free)", value: "glutenfree"},
      {label: "Kosher", value: "kosher"},
      {label: "Halal", value: "halal"},
      {label: "Eat In", value: "eatin"},
      {label: "Take Out", value: "takeout"},
      {label: "European", value: "european"},
      {label: "UK", value: "uk"},
      {label: "North America", value: "northamerica"},
      {label: "South America", value: "southamerica"},
      {label: "Mediterranean", value: "mediterranean"},
      {label: "East Asian", value: "eastasian"},
      {label: "South Asian", value: "southasian"},
      {label: "Arabic", value: "arabic"},
      {label: "African", value: "african"},
    ],
    store: {
      name: "",
      businessAddressLine1: "",
      businessAddressLine2: "",
      businessTown: "",
      businessCounty: "",
      businessPostcode: "",
      businessEmail: "",
      businessTelephone: "",
      lang: ["EN"],
      businessBidNo: "",
      businessBidOffersClaimed: "",
      businessRatesRefNo: [],
    },
    langs: ['EN', 'CY'],
  }),
  components: {
    BusinessRef,
    FileUpload: () => import('@/components/util/FileUpload.vue'),
    EditOpenTimes: () => import('@/components/EditOpenTimes.vue'),
  },
  computed: {
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
  },
  methods: {
    async initialise() {
      this.loading = true
      this.storeresults = await this.REDIS_read("org", this.GET_currentUserProfile.orgId).catch((err) => {
        console.log(err)
      })
      // console.log(this.storeresults)
      if (this.storeresults) {
        this.storeTitle = this.storeresults.data?.name
        this.store = this.storeresults.data
        if (this.store?.businessImageID === '') {
          this.imageID = this.MIX_generateId();
        } else {
          this.imageID = this.store.businessImageID;
        }
        if (this.store?.businessSquareImageID === '') {
          this.squareImageID = this.MIX_generateId();
        } else {
          this.squareImageID = this.store.businessSquareImageID;
        }
        console.log("imageID: "+this.imageID+" squareImageID: "+this.squareImageID)
        if (this.store.businessImageID !== '') {
          const storageRef = ref(this.$firebase.publicStorage, "orgImages" + '/' + this.store.businessImageID + '.' + this.store.businessImagetype);
          await getDownloadURL(storageRef).then((url) => {
            if (url !== undefined) {
              //console.log("download URL: "+url);
              this.storeIMG = url
            } else {
              this.storeIMG = ""
            }
          }).catch((err) => {
            console.log(err)
            this.storeIMG = this.MIX_getImgUrl('StoreHoldingBanner.jpg')
          });
        } else {
          this.storeIMG = this.MIX_getImgUrl('StoreHoldingBanner.jpg')
        }
        if (this.store.businessSquareImageID !== '') {
          const squareStorageRef = ref(this.$firebase.publicStorage, "orgImages" + '/' + this.store.businessSquareImageID + '.' + this.store.businessSquareTypeID);
          await getDownloadURL(squareStorageRef).then((url) => {
            if (url !== undefined) {
              //console.log("download URL: "+url);
              this.squareStoreIMG = url
            } else {
              this.squareStoreIMG = ""
            }
          }).catch((err) => {
            console.log(err)
            this.squareStoreIMG = this.MIX_getImgUrl('StoreHolding.jpg')
          });
        } else {
          this.squareStoreIMG = this.MIX_getImgUrl('StoreHolding.jpg')
        }

      } else {
        this.loading = false
      }
      //console.log("Store Deets: "+JSON.stringify(this.store))
      //console.log("Name: "+this.store.name)
      this.loading = false
    },
    async submitEditedorg() {
      //REDIS_update(collection, entityId, data)
      this.loading = true
      let errorcount = 0;
      let error = "";

      if (this.store.name == "") {
        errorcount++;
        error = "Please enter a business name"
      }
      if (this.store.businessAddressLine1 == "") {
        errorcount++;
        error = "Please enter a business address"
      }
      if (this.store.businessTown == "") {
        errorcount++;
        error = "Please enter a business town"
      }
      if (this.store.businessPostcode == "") {
        errorcount++;
        error = "Please enter a business postcode"
      }
      if (this.store.businessEmail == "") {
        errorcount++;
        error = "Please enter a business email"
      }
      if (this.store.businessTelephone == "") {
        errorcount++;
        error = "Please enter a business telephone"
      }
      if (!this.emailValidation(this.store.businessEmail)) {
        errorcount++;
        error = "Please enter a valid email address"
      }
      if (!this.postcodeValidation(this.store.businessPostcode)) {
        errorcount++;
        error = "Please enter a valid postcode"
      }
      // if(!this.phoneValidation(this.store.businessTelephone)){
      //     errorcount++;
      //     error = "Please enter a valid telephone number"
      // }
      if (errorcount <= 0) {
        console.log("submitting org:")
        console.log(this.store)
        await this.REDIS_update("org", this.GET_currentUserProfile.orgId, this.store)
        this.initialise();
        this.loading = false;
        // location.reload();
      } else {
        this.MIX_alertBox({show: true, message: error, color: "red", timeout: "4000"});
        this.loading = false;

      }
    },
    async copyToClipboard() {
      // console.log(this.$router)
      await navigator.clipboard.writeText(window.location.origin + '/business/' + this.store.entityId)
      this.MIX_alertBox({show: true, message: "Copied!", color: "green", timeout: "4000"});
    },
    uploadImage(fileType, fileID) {
      this.imageAdded = true
      // console.log("uploadImage header TYPE return: "+fileType+ " ID return: "+fileID)
      if(this.store.businessSquareImageID === fileID){
        this.store.businessImageID = this.MIX_generateId();
      }else{
        this.store.businessImageID = fileID;
        this.store.businessImagetype = fileType;
      }
      //console.log(this.form)
    },
    uploadSquareImage(fileType, fileID) {
      this.squareImageUploaded = true
      // console.log("uploadImage Square TYPE return: "+fileType+ " ID return: "+fileID)
      if(this.store.businessImageID === fileID){
        this.store.businessSquareImageID = this.MIX_generateId();
      }else {
        this.store.businessSquareImageID = fileID;
        this.store.businessSquareTypeID = fileType;
      }
      //console.log(this.form)
    },
    emailValidation(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    postcodeValidation(postcode) {
      let re = /^(SA1 |SA2 |SA3 |SA4 |SA5 |SA6 |SA7 |SA8 |SA18|SA99)/;
      return re.test(postcode);
    },
    phoneValidation(phone) {
      let re = /^\d{11}$/;
      return re.test(phone);
    },
    async getOffersClaimed(){
      return new Promise((resolve, reject) => {
        this.REDIS_searchFor("user-offer", "", "", "", "", `@status:{Claimed} @storeId:{${this.store.entityId}}`)
            .then((result) => {
              // console.log(result)
              this.offersClaimed = result.data.total;
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
      });
    }
  },
  created() {
    this.initialise()
    this.getOffersClaimed()
  }
};
</script>

<style scoped>
.noboxshadow {
  box-shadow: none !important;

}

.imgpopout {
  position: absolute;
  height: 11em;
  width: 90%;
  left: 5%;
  margin-top: -1em;
}

.belowimgpopout {
  padding-top: 10em;
}

.storeicons {
  padding-right: 3em !important;
}

.spacedcol {
  padding-bottom: 2em !important;
}

.storefield {
  padding-bottom: 5px !important;
}

@media only screen and (min-width: 768px) {
  .imgpopout {
    height: 12em;
    width: 95%;
    left: 2.5%;
  }

  .belowimgpopout {
    padding-top: 11em;
  }

  .spacedcol {
    padding-right: 2em !important;
    padding-bottom: 0em !important;
  }
}
</style>

<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}
</style>
