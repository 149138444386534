import { mapGetters } from "vuex";

const mixin = {
    data: () => ({
        apiUrl: process.env.VUE_APP_SWANSBIDSERVER,
    }),
    computed: {
        ...mapGetters({
            GET_currentUserAuth: "GET_currentUserAuth",
        }),
        apiOptions() {
            return {
                headers: { 
                    // 'Authorization': `Bearer ${this.GET_currentUserAuth.accesstoken}`,
                }
            }       
        }
    },
    methods: {
        // ...mapActions({
        //     ACT_noCloud: "ACT_noCloud",
        // }),
        REDIS_createNewUser(data, id) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}user/createwithid/${id}`;
                        //console.log('url', url)
                        let result = await this.$axios.post(url, data, this.apiOptions)
                        if (result.status === 201) {
                            //this.ACT_noCloud(false);
                            resolve( { success: true, data: result.data });
                        } else {
                            // this.ACT_noCloud(true);
                            reject( { success: false, error: result.error });
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // CREATE JSON RECORD IN REDIS
        REDIS_create(collection, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/`;
                        // console.log('url', url)
                        let result = await this.$axios.post(url, data, this.apiOptions)
                        // console.log('result = ', result)
                        if (result.status === 201) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // READ JSON RECORD FROM REDIS
        REDIS_read(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        //console.log(this.apiUrl)
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        //console.log(url)
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else if (result.status === 204) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // UPDATE JSON RECORD IN REDIS
        REDIS_update(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        //console.log('URL: '+url+'</br>'+'data'+data)
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // DELETE JSON RECORD IN REDIS BY MARKING AS DELETED
        REDIS_delete(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/delete/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RESTORE JSON RECORD IN REDIS BY REMOVING MARK AS DELETED
        REDIS_restore(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data)
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // DESTROY JSON RECORD IN REDIS BY REMOVING FROM REDIS
        REDIS_destroy(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.delete(url)
                        if (result.status === 202) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            })
        },
        // EXPIRE JSON RECORD IN REDIS BY SETTING TTL
        REDIS_expire(collection, entityId, expirySeconds) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/expire/${entityId}?seconds=${expirySeconds}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS
        REDIS_searchAll(collection, offset, count, sortBy, sortOrder) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/search/all?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON, ONLY USING COLLECTION
        REDIS_getAll(collection) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/search/all`;
                        let result = await this.$axios.get(url)
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS THAT MATCH QUERY
        REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // console.log( { collection, offset, count, sortBy, sortOrder, query } );
                        const url = `${this.apiUrl}${collection}/search/for?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}&query=${query}`;
                        // console.log(url)
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS THAT MATCH QUERY WITH GEOSEARCH
        REDIS_geoSearchFor(collection, lat, long, distance, sortBy, sortOrder, query) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        //http://localhost:8081/api/v1/org/coordinates/51.622385430135346,-3.949903275104114/radius/5,mi/search/for?query=@deleted:{false}
                        // console.log( { collection, offset, count, sortBy, sortOrder, query } );
                        const url = `${this.apiUrl}${collection}/coordinates/${lat},${long}/radius/${distance},mi/search/for?sortby=${sortBy}&sortorder=${sortOrder}&query=${query}`;
                        //console.log(url)
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN COUNT IN REDIS THAT MATCH QUERY
        REDIS_countFor(collection, offset, count, sortBy, sortOrder, query) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // console.log( { collection, offset, count, sortBy, sortOrder, query } );
                        const url = `${this.apiUrl}${collection}/count/for?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}&query=${query}`;
                        // console.log(url)
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            //this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },        
    }
}

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};