<template>
    <div class="paddingmobile">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <div class="pl-10 pr-10">
            <!-- <v-overlay
          :opacity="0.3"
          :value="loading"
          color="lightgrey"
        >
        <v-progress-circular indeterminate size="64" color="primary">
          </v-progress-circular>
        </v-overlay> -->
            <v-row>
                <v-col cols="6" xs="6" sm="6">
                    <div class="title font-weight-black primary--text">{{ $t("businesses") }}</div>
                </v-col>
            </v-row>
            <!-- PAGE CONTENT -->
            <v-row class="lightgrey rounded-of">
                <v-col cols="6" xs="6" sm="6" class="">
                    <SearchField :placeholder="$t('search')+ '...'" v-model="search" background="white"
                        style="padding-left:1em; max-width: 17em;" class="mobilesearch" />
                </v-col>
                <v-col cols="6" class="d-flex justify-end businessbutton" :style="$i18n.locale === 'cy' ? 'margin-left: -3em;' : ''" v-if="GET_currentUserProfile.level === 'superadmin'">
                    <AddStore v-on:addedStore="initialise()" :key="addStoreKey"/>
                </v-col>
                <!-- <v-col cols="12" xs="12" sm="12" class="pa-6" v-for="(event, index) in events" :key="index">
                    <pre> {{ event }}</pre>
                </v-col> -->
                <v-col cols='12'>
                    <v-data-table :items-per-page="pageCount" mobile-breakpoint="0" :items="stores" :search="search" :headers="storeHeaders" class="roundedtable">
                        <!-- <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Events</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template> -->
                        <template v-slot:[`item.businessStauts`]="{ item }">
                        <StoreOccupied :datadown="item"/>
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                            <div><EditStore :datadown="item" v-on:edited="initialise()" /></div>
                        </template>

                        <template v-slot:[`item.delete`]="{ item }">
                            <div><DeleteDialog collection="org" :entityId="item.entityId" :itemName="item.eventName" v-on:deleted="initialise()" /></div>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "AdminUsersView",
    data: () => ({
        loading: false,
        stores: [],
        search: "",
        addStoreKey: 0,
        pageCount: 10,
        storeHeaders: [
            { text: "Occupied?", value: "businessStauts" },
            { text: "Name", value: "name" },
            { text: "Telephone", value: "businessTelephone" },
            { text: "Email", value: "businessEmail" },
            { text: "Bid No", value: "businessBidNo" },
            { text: "Address Line 1", value: "businessAddressLine1" },
            { text: "Postcode", value: "businessPostcode" },
            { text: "Edit", value: "edit" },
            { text: "Delete", value: "delete" },],
    }),
    components: {
        AddStore: () => import("@/components/AddStore.vue"),
        EditStore: () => import("@/components/EditStore.vue"),
        DeleteDialog: () => import("@/components/util/DeleteDialog.vue"),
        StoreOccupied: () => import("@/components/util/StoreOccupied.vue"),
    },
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
    },
    methods: {
        async initialise() {
            this.loading = true;
            //let contactresult = await this.REDIS_getAll("contact");
            //REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query)
            let storeresult = await this.REDIS_searchFor("org", "", "", "createdDateTime", "desc", "@deleted:{false}");
            this.stores = storeresult.data.documents;
            this.addStoreKey++;
            this.loading = false;
            //console.log(JSON.stringify(this.stores, null, 2))
            //console.log(this.stores[1].name)
        },
    },
    created() {
        this.initialise();
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.paragraphtext {
    line-height: 1.4rem !important;
}

/* This removes padding and margin so the button aligns with the table on desktop */
.businessbutton{
    padding-right: 40px !important;
}

/* This removes padding and margin so the button aligns with the table on mobile */
@media all and (min-width:0px) and (max-width: 480px) {
    .businessbutton{
        padding-right: 0px !important;
        margin-right: 0px !important;
        padding-left: 50px !important;
    }
}
</style>
