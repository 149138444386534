<template>
    <v-navigation-drawer app permanent clipped stateless class="primary rounded-of-right-xl pt-7 navbarside" width="160">
        <v-row class="">
            <v-col cols="12" class="d-flex align-center" style="margin-bottom: -1em;" v-if="$vuetify.breakpoint.lgAndUp">
                <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo.png" contain height="80px" />
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/admin/dashboard active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text menubutton flexcol border-none removetexttransform"
                    block depressed><v-icon class="py-2 white--text sidebaricon">icons8-analytics</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("dashboard") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <template>
                <div class="text-center">
                    <v-menu transition="slide-x-transition" :close-on-content-click="false" :nudge-width="10" offset-x
                        rounded="0" class="navbarmenu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on"
                                class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                                block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-slider</v-icon>
                                <div class="pb-2 full-width text-center sidebarlabel">{{ $t("manage") }}</div>
                            </v-btn>
                        </template>
                        <v-card class="primary navbarcard foreground-high" style="overflow: hidden !important;">
                            <!-- MANAGE BUTTONS -->
                            <v-btn to=/admin/mystore active-class="secondary activepage"
                                class="text-body-2 font-weight-bold primary white--text innermenubutton flexcol border-none removetexttransform"
                                block depressed v-if="isShopAdmin()"><v-icon
                                    class="py-2 white--text sidebaricon">icons8-shop-location</v-icon>
                                <div class="pb-2 full-width text-center sidebarlabel">{{ $t("mybusiness") }}</div>
                            </v-btn>

                            <v-btn to=/admin/offers active-class="secondary activepage"
                                class="text-body-2 font-weight-bold primary white--text rounded-of innermenubutton flexcol removetexttransform"
                                block depressed x-large><v-icon
                                    class="py-2 white--text sidebaricon">icons8-sale-price-tag</v-icon>
                                <div class="pb-2 full-width text-center sidebarlabel">{{ $t("offers") }}</div>
                            </v-btn>

                            <v-btn to=/admin/events active-class="secondary activepage"
                                class="text-body-2 font-weight-bold primary white--text rounded-of innermenubutton flexcol removetexttransform"
                                block depressed x-large><v-icon
                                    class="py-2 white--text sidebaricon">icons8-calendar-17</v-icon>
                                <div class="pb-2 full-width text-center sidebarlabel">{{ $t("events") }}</div>
                            </v-btn>

                            <v-btn to=/admin/businesses active-class="secondary activepage" v-if="isAdmin()"
                                class="text-body-2 font-weight-bold primary white--text innermenubutton flexcol border-none removetexttransform"
                                block depressed><v-icon class="py-2 white--text sidebaricon">icons8-shop-location</v-icon>
                                <div class="pb-2 full-width text-center sidebarlabel">{{ $t("businesses") }}</div>
                            </v-btn>

                          <v-btn to=/admin/admin-users active-class="secondary activepage" v-if="isAdmin()"
                                 class="text-body-2 font-weight-bold primary white--text innermenubutton flexcol border-none removetexttransform"
                                 block depressed><v-icon class="py-2 white--text sidebaricon">icons8-people</v-icon>
                            <div class="pb-2 full-width text-center sidebarlabel">{{ $t("businessusers") }}</div>
                          </v-btn>

                            <v-btn to=/admin/users active-class="secondary activepage" v-if="isAdmin()"
                                class="text-body-2 font-weight-bold primary white--text innermenubutton flexcol border-none removetexttransform"
                                block depressed><v-icon class="py-2 white--text sidebaricon">icons8-male-user</v-icon>
                                <div class="pb-2 full-width text-center sidebarlabel">{{ $t("users") }}</div>
                            </v-btn>
                        </v-card>
                    </v-menu>
                </div>
            </template>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to="/admin/social" active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon
                        class="py-2 white--text sidebaricon">icons8-hand-with-smartphone</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("social") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/admin/Contacts active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-phone</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("contacts") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" v-if="GET_currentUserProfile.level === 'superadmin'" class="text-center navbarbuttoncol">
                <v-btn to=/admin/bidmembers active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text menubutton flexcol border-none removetexttransform"
                    block depressed><v-icon class="py-2 white--text sidebaricon">icons8-people</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("ballotservices") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/admin/scan active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text menubutton flexcol border-none removetexttransform"
                    block depressed><v-icon class="py-2 white--text sidebaricon">icons8-hand-with-smartphone</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("scan") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center navbarbuttoncol">
                <v-btn to=/admin/cards active-class="secondary activepage"
                    class="text-body-2 font-weight-bold primary white--text menubutton flexcol border-none removetexttransform"
                    block depressed><v-icon class="py-2 white--text sidebaricon">icons8-quality</v-icon>
                    <div class="pb-2 full-width text-center sidebarlabel">{{ $t("cardlist") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center pt-16">
                <v-btn @click="adminLogout()"
                    class="text-body-2 font-weight-bold primary white--text rounded-of menubutton flexcol removetexttransform"
                    block depressed x-large><v-icon class="py-2 white--text sidebaricon">icons8-logout</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("return") }}</div>
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-center" style="margin-top: -1.5em;">
                <div class="caption white--text text--darken-3 full-width text-center mt-2">{{ $t("version") }} {{ appVersion }}</div>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
//import { version } from "../../../package.json";
export default {
    name: "AdminSideNav",
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        appVersion() {
            return require('../../../package.json').version;
        }
    },
    methods: {
        ...mapActions({
            ACT_isAdmin: "ACT_isAdmin",
            ACT_authenticated: "ACT_authenticated",
        }),
        adminLogout() {
            this.ACT_isAdmin(false);
            this.ACT_authenticated(true);
            this.$router.push("/home");
        },
        isAdmin(){
            if(this.GET_currentUserProfile){
                if(this.GET_currentUserProfile.level === 'superadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
        isShopAdmin(){
            if(this.GET_currentUserProfile){
                if(this.GET_currentUserProfile.level === 'shopadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
    },
};
</script>

<style scoped>
.menubutton {
    min-height: 20px !important;
    height: 65px !important;
    border-radius: 0px !important;
}


.innermenubutton {
    min-height: 20px !important;
    height: 75px !important;
    border-radius: 0px !important;
}

.navbarbuttoncol {
    padding-bottom: 0px !important;
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
}

.menupanel {
    padding: 1em !important;
    max-height: none !important;
}

.sidebaricon {
    font-size: 2.2em;
}

.activepage {
    background-color: white !important;

}

.activepage .sidebaricon {
    color: #CC0200 !important;
}

.activepage .sidebarlabel {
    color: #CC0200 !important;
}

.v-menu__content{
    box-shadow: none !important;
}

.navbarcard{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
</style>