<template>
    <v-navigation-drawer v-model="showAdminDrawer" absolute temporary app bottom class="foreground-highest primary rounded-of-top-xl menupanel" height="auto" width="100%" overlay-opacity="0.8" id="adminbottomnav">
        <v-row class="">
            <v-col cols="6" class="text-center px-5">
                <v-btn to=/admin/dashboard class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-analytics</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("dashboard") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn to="/admin/scan" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-hand-with-smartphone</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("scan") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isShopAdmin()">
                <v-btn to="/admin/mystore" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-shop-location</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("mybusiness") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn to="/admin/offers" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-sale-price-tag</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("offers") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn to="/admin/events" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-calendar-17</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("events") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isAdmin()"> 
                <v-btn to="/admin/businesses" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-shop-location</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("businesses") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn to="/admin/social" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-hand-with-smartphone</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("social") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn to=/admin/Contacts class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-phone</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("contacts") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn to=/admin/cards class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-quality</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("cardlist") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isAdmin()"> 
                <v-btn to="/admin/bidmembers" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-male-user</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("ballotservices") }}</div></v-btn
                >
            </v-col>
          <v-col cols="6" class="text-center px-5" v-if="isAdmin()">
            <v-btn to="/admin/admin-users" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
            ><v-icon class="pb-2 white--text" x-large>icons8-people</v-icon>
              <div class="pb-2 full-width text-center">{{ $t("businessusers") }}</div></v-btn
            >
          </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isAdmin()"> 
                <v-btn to="/admin/users" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-male-user</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("users") }}</div></v-btn
                >
            </v-col>
            <v-col cols="12" class="text-center px-5">
                <div class="caption white--text text--darken-3 full-width text-center mt-2">Version {{ appVersion }}</div>
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn @click="adminLogout()" class="primary text-body-2 font-weight-bold white--text removetexttransform" block x-large depressed
                    ><v-icon class="pb-2 white--text" x-large>icons8-logout</v-icon>
                    <div class="pb-2 full-width text-center">Return</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <ProfileDialog :userProfile="GET_currentUserProfile" dialogTitle="My Profile"/>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>

<script>
//import { version } from "../../../package.json";
import { mapGetters, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters({
            GET_showAdminDrawer: "GET_showAdminDrawer",
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        appVersion() {
            const version = require('../../../package.json').version
            return version;
        },
        computedViewportheight() {
            return window.innerHeight;
        },
    },
    data: () => ({
        showAdminDrawer: false,
        items: [
            { title: "Home", icon: "mdi-view-dashboard" },
            { title: "About", icon: "mdi-forum" },
        ],
    }),
    methods: {
        ...mapActions({
            ACT_showAdminDrawer: "ACT_showAdminDrawer",
            ACT_isAdmin: "ACT_isAdmin",
            ACT_authenticated: "ACT_authenticated",
        }),
        adminLogout() {
            this.ACT_isAdmin(false);
            this.ACT_authenticated(true);
            this.$router.push("/home");
        },
        updateshowAdminDrawer() {
            this.ACT_showAdminDrawer(!this.GET_showAdminDrawer);
        },
        isAdmin(){
            if(this.GET_currentUserProfile){
                if(this.GET_currentUserProfile.level === 'superadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
        isShopAdmin(){
            if(this.GET_currentUserProfile){
                if(this.GET_currentUserProfile.level === 'shopadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
    },
    components:{
        ProfileDialog: () => import("@/components/card/ProfileDialog.vue"),
    },
    watch: {
        GET_showAdminDrawer: function () {
            this.showAdminDrawer = this.GET_showAdminDrawer;
            //onsole.log("ADMIN CLOSED");
            document.getElementById("adminbottomnav").style.display="none"
        },
        showAdminDrawer: function () {
            this.ACT_showAdminDrawer(this.showAdminDrawer);
            //console.log("ADMIN VISIBLE");
            document.getElementById("adminbottomnav").style.display="block"
        },
    },
    created() {
        this.showAdminDrawer = this.GET_showAdminDrawer;
    },
};
</script>

<style>
/* .v-btn__content {
background-color: black;
} */
</style>

<style scoped>
.menubutton {
    min-height: 20px !important;
    height: 80px !important;
}
.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
}
.menupanel{
    padding: 1em !important;
    max-height: none !important;
    display: none;
}
</style>
