<template>
    <div>
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <v-row class="d-flex align-center justify-center">
            <!-- <v-col cols="12" class="pl-10">
                <div class="title font-weight-black primary--text">Offer Scanner</div>
            </v-col> -->
            <v-col cols="10" class="rounded-of lightgrey pa-0 mt-10" style="overflow:hidden">
                <qrcode-stream @decode="onDecode" :track="paintOutline" @init="onInit" :torch="flashlight" :camera="camera" class="qrwindow">
                    <div class="title py-4 text-center font-weight-black white--text rounded-of-top" style="background: linear-gradient(black, rgba(255, 0, 0, 0));"><b class="white--text">{{ $t("OfferScanner") }}</b><br>{{ $t("ScanTheQRCodeBelow:") }}</div>
                </qrcode-stream>
            </v-col>
        </v-row>
        <v-row class="" style="max-width:40px !important;">
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card class="white pa-7 rounded-of">
                    <v-overlay :opacity="0.3" :value="loading" color="white">
                        <v-progress-circular indeterminate size="64" color="primary">
                        </v-progress-circular>
                    </v-overlay>
                    <v-row class="white">
                        <v-col cols="12">
                            <div class="title primary--text font-weight-black text-center px-5">Offer Details</div>
                            <div class="subtitle grey--text text-center px-5">ID: {{ useroffer.entityId }}</div>
                            <div class="title black--text text-center px-5">Added: <b class="primary--text">{{
                                MIX_formatDateTime(useroffer.createdDateTime, 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'DD MMMM YYYY')
                            }}</b></div>
                            <div class="title black--text text-center px-5">User: <b class="primary--text">{{
                                userdetails.firstName + ' ' + userdetails.lastName }}</b></div>
                            <div class="title black--text text-center px-5">Email: <b class="primary--text">{{
                                userdetails.email }}</b></div>
                            <div class="title black--text text-center px-5">Offer Title: <b class="primary--text">{{
                                offerdetails.offerTitle }}</b></div>
                            <div class="title black--text text-center px-5">Offer Type: <b class="primary--text">{{
                                offerdetails.offerType }}</b></div>
                        </v-col>
                        <!-- <v-col cols="12" v-if="useroffer.status === 'claimed'">
                            <div class="title primary--text font-weight-black text-center px-5">This Offer has been Claimed</div>
                        </v-col> -->
                    </v-row>
                    <v-card-actions class="justify-center">
                        <v-btn color="subtitle-1 px-6 grey rounded-of removetexttransform" depressed @click="refresh()">
                            Close
                        </v-btn>
                        <!-- <v-btn color="subtitle-1 px-6 primary rounded-of removetexttransform" depressed
                        @click="claimOffer()">
                        Claim
                    </v-btn> -->
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "ParkingView",
    data: () => ({
        result: '',
        error: '',
        dialog: false,
        loading: false,
        useroffer: [],
        offerdetails: [],
        userdetails: [],
    }),
    computed: {
        ...mapGetters({
            GET_showDrawer: 'GET_showDrawer',
            GET_showFilterDrawer: 'GET_showFilterDrawer',
            GET_authenticated: 'GET_authenticated',
        }),
    },
    methods: {
        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
        async onInit(promise) {
            //console.log("onInit")
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
                this.MIX_alertBox({ show: true, message: this.error, color: "error", timeout: "4000" });
            }
        },

        async onDecode(result) {
            this.loading = true
            let useroffer = await this.REDIS_read("user-offer", result);
            // console.log(useroffer)
            if (useroffer == null) {
                // console.log("no offer found")
                this.MIX_alertBox({ show: true, message: "Code Invalid", color: "error", timeout: "4000" });
                this.loading = false
                return
            } else {
                // console.log(useroffer)
                this.useroffer = useroffer.data;
                let offerdetails = await this.REDIS_read("offer", useroffer.data.offerId);
                this.offerdetails = offerdetails.data;
                let userdetails = await this.REDIS_read("user", useroffer.data.userId);
                this.userdetails = userdetails.data
                this.loading = false
                this.dialog = true
            }
        },
        refresh() {
            location.reload();
        }

    },
    components: {
    },
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.v-carousel__controls {
    margin-top: 200px !important;
}

.qrwindow.qrcode-stream-camera{
    border-radius: 15px;
}

.qrcode-stream-camera{
    border-radius: 15px !important;
}

</style>
