<template>
    <v-app style="overflow-x: hidden !important">
        <NavDrawer/>
        <AdminNavDrawer/>
        <NoConnection v-if="noconnection" v-on:close="noconnection = false"/>
        <AppUpdate v-if="appupdate" v-on:refreshApp="refreshApp()"/>
        <PwaInstall v-if="$vuetify.breakpoint.smAndDown && !GET_hideInstallDialog" v-on:dismiss="dismissInstall"/>
        <!-- <v-main class="" :style="GET_showFilterDrawer || GET_showDrawer  || GET_showAdminDrawer ? 'position: fixed; overflow: none;':''" style="max-height: 100vh !important" > -->
        <v-main :style="GET_showFilterDrawer || GET_showDrawer  || GET_showAdminDrawer ? 'position: fixed; overflow: none;':''">
            <AdminSideNav v-if="$vuetify.breakpoint.mdAndUp && GET_isAdmin && !isLoginPage"></AdminSideNav>
            <SideNav v-if="$vuetify.breakpoint.mdAndUp && !this.GET_isAdmin && !isLoginPage" :key="navKey"/>
            <LanguageSelector class="foreground-high"></LanguageSelector>
            <!-- <NoAuth v-if="!GET_authenticated && !isLoginPage"></NoAuth> -->
            <transition enter-active-class="animate__animated animate__fadeIn animate__faster"
                        leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                <routerView v-on:refreshnav="navKey+= 1"/>
            </transition>
        </v-main>
        <div class="footerbanner frontend-low" v-if="$vuetify.breakpoint.smAndDown && GET_authenticated"></div>
        <BottomNav v-if="$vuetify.breakpoint.smAndDown && !this.GET_isAdmin && !isLoginPage" :key="navKey"/>
        <AdminBottomNav v-if="$vuetify.breakpoint.smAndDown && GET_isAdmin && !isLoginPage"/>
        <AlertBox/>
    </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NavDrawer from "./components/nav/NavDrawer.vue";
import SideNav from "./components/nav/SideNav.vue";
import BottomNav from "./components/nav/BottomNav.vue";
import AppUpdate from "./components/util/AppUpdate.vue";
import NoConnection from "./components/util/NoConnection.vue";
import LanguageSelector from '@/components/util/LanguageSelector.vue';
import AlertBox from '@/components/util/AlertBox.vue';
import PwaInstall from '@/components/util/PwaInstall.vue';
// import NoAuth from "./components/NoAuth.vue";
//ADMIN IMPORT
import AdminSideNav from "./components/admin/AdminSideNav.vue";
import AdminBottomNav from "./components/admin/AdminBottomNav.vue";
import AdminNavDrawer from "./components/admin/AdminNavDrawer.vue";

import {RouterView} from "vue-router";

export default {
    name: "App",
    components: {
        NavDrawer,
        SideNav,
        BottomNav,
        AppUpdate,
        NoConnection,
        RouterView,
        // NoAuth,
        AdminSideNav,
        AdminBottomNav,
        AdminNavDrawer,
        LanguageSelector,
        AlertBox,
        PwaInstall
    },

    data: () => ({
        userAuthenticated: false,
        noconnection: false,
        appupdate: false,
        isAdmin: false,
        navKey: 0,
        registration: null
    }),
    computed: {
        ...mapGetters({
            GET_showDrawer: 'GET_showDrawer',
            GET_showFilterDrawer: 'GET_showFilterDrawer',
            GET_authenticated: 'GET_authenticated',
            GET_isAdmin: 'GET_isAdmin',
            GET_showAdminDrawer: "GET_showAdminDrawer",
            GET_currentUserProfile: "GET_currentUserProfile",
            GET_hideInstallDialog: "GET_hideInstallDialog",
        }),
        isLoginPage() {
            if (this.$route.name == 'Login' || this.$route.name == 'AdminLogin' || this.$route.name == 'AdminRegisterView' || this.$route.name == 'ShopAdminRegisterView' || this.$route.name == 'Register') {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        ...mapActions({
            ACT_hideInstallDialog: "ACT_hideInstallDialog",
        }),
        updateAvailable(event) {
            this.registration = event.detail
            this.appupdate = true
        },
        refreshApp() {
            this.noconnection = false
            if (!this.registration || !this.registration.waiting) return
            this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
        },
        // async checkUser() {
        //     if (this.MIX_tokenToUser(localStorage.userToken).given_name != '' && !this.GET_isAdmin) {
        //         //console.log("User Data is Stored in Local Storage")
        //         await this.ACT_authenticated(true);
        //     }
        // },
        async dismissInstall(){
            // console.log("dismissInstall")
            await this.ACT_hideInstallDialog(true);
        },
        delay(ms) {
          const startPoint = new Date().getTime();
          while (new Date().getTime() - startPoint <= ms) {
            //and now we wait
          }
        }
    },
    created() {
        this.MIX_alertBox({ show: false, message: '', color: "error", timeout: "4000" });
        document.addEventListener('swUpdated', this.updateAvailable, {once: true})
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return
            this.refreshing = true
            window.location.reload();
        })
        // this.checkUser();
        // console.log(this.GET_hideInstallDialog)
    },
    beforeMount() {
      this.delay(500);
    }
};
</script>

<style>
/* WIDTH / HEIGHT */
.full-width {
    width: 100% !important;
}

.half-width {
    width: 50% !important;
}

.full-height {
    height: 100% !important;
}

.full-viewpoint-width {
    width: 100vw !important;
}

.full-viewpoint-height {
    height: 100vh !important;
}

/* REMOVE BANNER LINE */
.v-banner__wrapper {
    border-bottom: 0px !important;
}

/* POSITIONING */
.fixed {
    position: fixed !important;
}

/* Z-INDEX HIERARCHY */
.foreground-highest {
    z-index: 99999 !important;
}

.foreground-high {
    z-index: 9999 !important;
}

.foreground-medium {
    z-index: 999 !important;
}

.foreground-low {
    z-index: 99 !important;
}

/* ROUNDED CORNERS */
.rounded-of {
    border-radius: 15px !important;
}

.rounded-of-left {
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
}

.rounded-of-right {
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.rounded-of-xl {
    border-radius: 50px !important;
}

.rounded-of-top {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.rounded-of-right-xl {
    border-bottom-right-radius: 50px !important;
    border-top-right-radius: 50px !important;
}

.rounded-of-left-xl {
    border-bottom-left-radius: 50px !important;
    border-top-left-radius: 50px !important;
}

.rounded-of-top-mid {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
}

.rounded-of-top-xl {
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
}

.rounded-of-bottom {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.border-none {
    border-radius: none !important;
}

.footerbanner {
    bottom: 0px;
    width: 100vw !important;
    position: fixed !important;
    margin-bottom: 50px !important;
    height: 70px !important;
    background: -moz-linear-gradient(bottom, rgb(224, 224, 224) 20%, rgba(243, 243, 244, 0) 90%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, rgb(224, 224, 224) 20%, rgba(243, 243, 244, 0) 90%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(224, 224, 224) 20%, rgba(243, 243, 244, 0) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

/* THIS CLASS IS FOR THE VERTICAL BUTTONS*/
.flexcol .v-btn__content {
    flex-direction: column;
    align-items: center !important;
    height: 80px !important;
}

.removetexttransform {
    text-transform: none !important;
}

.dropshadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.headericon {
    height: 150px;
    max-width: 150px;
}

/* fix for the button text color */
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    color: white !important;
}

.paddingmobile {
    padding-bottom: 8em !important;
}

@media screen and (max-height: 900px) {
    .headericon {
        height: 90px;

    }

    .paddingmobile {
        padding-bottom: 0em !important;
    }
}

/* This removes margin on mobile devices so the footer fits on one line */
@media all and (min-width: 0px) and (max-width: 480px) {
    .v-application--is-ltr .v-data-footer__select {
        margin-left: 10px !important;
        margin-right: 0px !important;
    }

    .v-application--is-ltr .v-data-footer__select .v-select {
        margin-left: 10px !important;
    }

    .businessbutton {
        padding-right: 0px !important;
        margin-right: 0px !important;
        align-items: right !important;
    }

}

/* This removes the left padding from the search bar so it lines up with the table */
.mobilesearch {
    padding-left: 0px !important;
}

</style>