<template>
    <div>
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <!-- <pre> EID: {{ $route.params.entityId }}</pre> -->
      <div class="pb-3">
        <v-btn depressed class="white d-flex align-center" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
          <span class="pl-2">{{ $t("back") }}</span>
        </v-btn>
      </div>
        <v-container style="padding-left: 3em !important; padding-right: 3em !important;">
            <v-overlay
          :opacity="0.2"
          :value="loading"
          color="lightgrey"
        >
          <v-progress-circular indeterminate size="64" color="primary">
          </v-progress-circular>
        </v-overlay>
            <v-row class="dense lightgrey rounded-of">
                <!--<v-col cols="12" class="text-center">-->
                <!--    <v-img max-height="100%" max-width="100%"-->
                <!--        class="rounded-of imgpopout dropshadow" :src='eventIMG' :lazy-src="MIX_getImgUrl('StoreHoldingBanner.jpg')"></v-img>-->
                <!--</v-col>-->
                <v-col cols="12" xs="12" sm="12" lg="12" xl="12" class="mt-4 ml-4 d-flex align-items-space-between" :class="$vuetify.breakpoint.width <=700 ? 'flex-column' : ''">
                    <v-img height="15em" width="15em" style="max-width: 15em; max-height: 15em;" class="rounded-of dropshadow mr-8" :class="$vuetify.breakpoint.width <=700 ? 'mb-4' : ''"
                           :src='eventIMG'></v-img>
                    <v-row>
                        <v-col cols="8">
                            <div class="title text-left">
                                {{ eventresult.data.eventName }}
                            </div>
                            <div class="subtitle-1 text-left pb-4" v-if="eventresult.data.eventVenue !== ''">
                                {{eventresult.data.eventVenue}}
                            </div>
                            <div class="subtitle-1 text-left font-weight-bold">
                                {{  MIX_formatDateTime(eventresult.data.eventDateTime, 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'DD MMMM YYYY') }}
                            </div>
                            <div class="subtitle-1 text-left" v-if="$i18n.locale === 'en'">
                                {{eventresult.data.eventOpenClose}}
                            </div>
                            <div class="subtitle-1 text-left" v-if="$i18n.locale === 'cy'">
                                {{eventresult.data.eventOpenClose.replace("am", "yb").replace("pm", "yp")}}
                            </div>
                        </v-col>
                        <v-col cols="4" class="d-flex justify-end align-end storeicons">
                            <v-icon @click="toggleFavourite()" v-if="!userFavourited && MIX_isLoggedIn()" class="primary--text pl-1" large>icons8-favorite</v-icon>
                            <v-icon @click="toggleFavourite()" v-if="userFavourited && MIX_isLoggedIn()" class="primary--text pl-1" large>icons8-heart</v-icon>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="ml-4">
                    <v-row class="">
                        <!--<v-col cols="12" class="">-->
                        <!--    <v-row>-->
                        <!--        <v-col cols="8">-->
                        <!--            <div class="title text-left">-->
                        <!--                {{ eventresult.data.eventName }}-->
                        <!--            </div>-->
                        <!--            <div class="subtitle-1 text-left pb-4" v-if="eventresult.data.eventVenue !== ''">-->
                        <!--              {{eventresult.data.eventVenue}}-->
                        <!--            </div>-->
                        <!--            <div class="subtitle-1 text-left font-weight-bold">-->
                        <!--                {{  MIX_formatDateTime(eventresult.data.eventDateTime, 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'DD MMMM YYYY') }}-->
                        <!--            </div>-->
                        <!--            <div class="subtitle-1 text-left" v-if="$i18n.locale === 'en'">-->
                        <!--                {{eventresult.data.eventOpenClose}}-->
                        <!--            </div>-->
                        <!--            <div class="subtitle-1 text-left" v-if="$i18n.locale === 'cy'">-->
                        <!--                {{eventresult.data.eventOpenClose.replace("am", "yb").replace("pm", "yp")}}-->
                        <!--            </div>-->
                        <!--        </v-col>-->
                        <!--        <v-col cols="4" class="d-flex justify-end align-end storeicons">-->
                        <!--            <v-icon @click="toggleFavourite()" v-if="!userFavourited && MIX_isLoggedIn()" class="primary&#45;&#45;text pl-1" large>icons8-favorite</v-icon>-->
                        <!--            <v-icon @click="toggleFavourite()" v-if="userFavourited && MIX_isLoggedIn()" class="primary&#45;&#45;text pl-1" large>icons8-heart</v-icon>-->
                        <!--        </v-col>-->
                        <!--    </v-row>-->
                        <!--</v-col>-->
                        <v-col cols="12">
                            <p style="" class="pr-4">{{ eventresult.data.eventDetails }}</p>
                        </v-col>
                        <v-col cols="12" class="" v-if="!eventresult.data?.isOnline && eventresult.data.location.latitude !== 0">
                            <v-btn :href="'https://www.google.com/maps/search/?api=1&query='+eventresult.data.location.latitude+ ',' +eventresult.data.location.longitude"
                                class="font-weight-bold primary white--text rounded-of removetexttransform" style="height: 5em; width: 100%;; margin-left: -1em;" depressed>
                                <v-icon class="white--text" x-large>icons8-place-marker</v-icon>
                                <div class="title full-width text-center pl-2">{{ $t("directionstoevent") }}</div>
                            </v-btn>
                        </v-col>
                      <v-col cols="12" class="" v-if="eventresult.data?.isOnline && eventresult.data?.eventURL === ''">
                        <v-btn class="font-weight-bold primary white--text rounded-of removetexttransform" style="height: 5em; width: 100%;; margin-left: -1em;" depressed disabled>
                          <v-icon class="white--text" x-large>icons8-place-marker</v-icon>
                          <div class="title full-width text-center pl-2">This event is Online</div>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="" v-if="eventresult.data.eventURL !== '' && eventresult.data.eventURL !== undefined && eventresult.data.eventURL !== null">
                        <v-btn :href="eventresult.data?.eventURL" class="font-weight-bold primary white--text rounded-of removetexttransform" style="height: 5em; width: 100%;; margin-left: -1em;" depressed>
                          <v-icon class="white--text" x-large>icons8-link </v-icon>
                          <div class="title full-width text-center pl-2">Event Website</div>
                        </v-btn>
                      </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref, getDownloadURL } from "firebase/storage";
export default {
    name: "EventView",
    data: () => ({
        eventresult: {
            data: {
                eventName: "",
                eventDateTime: "",
                eventOpenClose: "",
                eventDetails: "",
                eventImageID: "",
                eventImagetype: "",
                location: {
                    longitude: 0,
                    latitude: 0,}
            }
        },
        eventOpenClose: "",
        userFavourited: false,
        favouriteId: "",
        stat: {
            statType: "eventFavourite",
            userId: "",
            orgId: "",
            deleted: false,
        },
        restore: {
            deleted: false,
        },
        loading: false,
        eventIMG: "",
    }),
    components: {
    },
    computed:{
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
    },
    methods:{
        async getEventDetails(){
            this.loading = true;
            this.eventresult = await this.REDIS_read("event", this.$route.params.entityId)
            if(this.eventresult.data.eventImageID !== ''){
                const storageRef = ref(this.$firebase.publicStorage, "eventImages" + '/' + this.eventresult.data.eventSquareImageID + '.' + this.eventresult.data.eventSquareImageType);
                await getDownloadURL(storageRef).then((url) => {
                    if(url !== undefined){
                        //console.log("download URL: "+url);
                        this.eventIMG = url
                    } else{
                        this.eventIMG = ""
                    }
                });
            }else{
                this.eventIMG = this.MIX_getImgUrl('StoreHolding.jpg')
            }
            // console.log("Event Deets: "+JSON.stringify(this.eventresult))
            this.loading = false
    },
    async getFavourite(){
            return new Promise((resolve, reject) => {
                // console.log("UserId"+ this.GET_currentUserProfile.entityId)
                // console.log(JSON.stringify(this.storedetails.entityId))
                if(this.GET_currentUserProfile != null){
                    this.REDIS_searchFor("stats", "", "", "", "", `@statType:{eventFavourite} @userId:{${this.GET_currentUserProfile.entityId}} @eventId:{${this.$route.params.entityId}}`)
                    .then((result) => {
                    if(result.data.total >= 0){
                        this.favouriteId = result.data.documents[0]?.entityId
                        // console.log("ID: "+JSON.stringify(this.favouriteId))
                        //console.log(result.data.documents[0].deleted)
                        if(result.data.documents[0]?.deleted === false){
                            this.userFavourited = true
                        }else{
                            this.userFavourited = false
                        }
                    }else{
                        this.userFavourited = false
                    }
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
                } else if(localStorage.userToken){
                    this.REDIS_searchFor("stats", "", "", "", "", `@statType:{eventFavourite} @userId:{${this.MIX_tokenToUser(localStorage.userToken).oid.replace(/-/g, "")}} @eventId:{${this.$route.params.entityId}}`)
                    .then((result) => {
                    if(result.data.total >= 0){
                        this.favouriteId = result.data.documents[0].entityId
                        // console.log("ID: "+JSON.stringify(this.favouriteId))
                        //console.log(result.data.documents[0].deleted)
                        if(result.data.documents[0].deleted === false){
                            this.userFavourited = true
                        }else{
                            this.userFavourited = false
                        }
                    }else{
                        this.userFavourited = false
                    }
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
                }
            });
        },
        async toggleFavourite(){
            this.loading = true
            if(this.userFavourited){
                // console.log("will delete")
                await this.REDIS_delete("stats", this.favouriteId)
                this.userFavourited = false
                this.getFavourite()
            }else{
              // console.log("favID: "+this.favouriteId)
                if(this.favouriteId === "" || this.favouriteId === undefined || this.favouriteId === null){
                    if(this.GET_currentUserProfile != null){
                        this.stat.userId = this.GET_currentUserProfile.entityId
                    }else if(localStorage.userToken){
                        this.stat.userId = this.MIX_tokenToUser(localStorage.userToken).oid.replace(/-/g, "");
                        //console.log("set user ID to "+this.stat.userId)
                    }else{
                        // console.log("No user logged in")
                        this.loading = false
                        this.userFavourited = false
                        return
                    }
                    this.stat.eventId = this.$route.params.entityId
                    this.stat.deleted = false
                    // console.log(JSON.stringify(this.$route.params.entityId))
                    await this.REDIS_create("stats", this.stat)
                    this.userFavourited = true
                    this.getFavourite()
                }else{
                    // console.log("Restore")
                    await this.REDIS_restore("stats", this.favouriteId, this.restore)
                    this.getFavourite()
                }
            }
            this.loading = false
        },
    },
    async created(){
        await this.getEventDetails();
        await this.getFavourite();
        // console.log(this.userFavourited)
    }
};
</script>

<style scoped>
.noboxshadow {
    box-shadow: none !important;

}
.imgpopout{
    position: absolute;
    height: 11em;
    width: 90%;
    left:5%;
    margin-top: -1em;
}

.belowimgpopout{
    padding-top: 10em;
}

@media only screen and (min-width: 768px) {
    .imgpopout{
        height:17em;
        width: 95%;
        left: 2.5%;
    }
    .belowimgpopout{
    padding-top: 16em;
}
} 

.storeicons{
    padding-right: 3em !important;
}
</style>
