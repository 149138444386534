import Vue from 'vue'
import App from '@/App.vue'
import router from '@/plugins/router.js'
import store from '@/plugins/store.js'
import vuetify from '@/plugins/vuetify.js'
import '@/plugins/registerServiceWorker.js'
import i18n from "./i18n";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

// Animations Import
import "animate.css";
// Icons8 CSS Import
import "@/assets/css/styles.min.css";

// Vue Carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// Input Components Import
import TextField from "@/components/form/TextField.vue";
Vue.component('TextField', TextField);
import DropdownSelect from "@/components/form/DropdownSelect.vue";
Vue.component('DropdownSelect', DropdownSelect);
import SearchField from "@/components/form/SearchField.vue";
Vue.component('SearchField', SearchField);
import DatePicker from "@/components/form/DatePicker.vue";
Vue.component('DatePicker', DatePicker);
import TimePicker from "@/components/form/TimePicker.vue";
Vue.component('TimePicker', TimePicker);
import PasswordField from "@/components/form/PasswordField.vue";
Vue.component('PasswordField', PasswordField);
import CheckboxField from "@/components/form/CheckboxField.vue";
Vue.component('CheckboxField', CheckboxField);

// Redis Integration
import redisMixin from "@/redis/redisMixin.js";
Vue.use(redisMixin);

// Vuetify Google Autocomplete
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyCDegVfnIXWw2kRlbd9FsQU-5OVbBmFhnI', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  // version: '...', // Optional
  // language: '...', // Optional
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

// Geolocation
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

//Fuse.js Integration
import Fuse from "fuse.js";
Vue.prototype.$fuse = Fuse;
// Axios Integration
import axios from "axios";
Vue.prototype.$axios = axios;

//QR Code Scannning
import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

//* FIREBASE INTEGRATION
const firebase = require("@/firebase/firebaseConfig.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebaseMixin from "@/firebase/firebaseMixin";
Vue.use(firebaseMixin);

// Other Components
import DateCalendar from "@/components/card/DateCalendar.vue";
Vue.component('DateCalendar', DateCalendar);

// Date Library
import dayjs from "dayjs";
var customParseFormat = require('dayjs/plugin/customParseFormat');
require('dayjs/locale/cy');
Vue.prototype.$dayjs = dayjs.extend(customParseFormat);
import sampledata from "@/sampleData.js"
Vue.prototype.$sampledata = sampledata;
// Mixin
import mixin from "@/plugins/mixin.js";
Vue.use(mixin);

// Language
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
