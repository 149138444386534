<template>
    <v-navigation-drawer v-model="showDrawer" app bottom temporary class="foreground-highest primary rounded-of-top-xl menupanel" height="auto" width="100%" overlay-opacity="0.8" id="bottomnav">
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-btn @click="updateShowDrawer" class="primary rounded-of mr-3" depressed>
              <v-icon class="white--text">icons8-expand-arrow</v-icon>
            </v-btn>
          </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isSwanseaResident()">
                <v-btn to=/home class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-home</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("home") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn @click="MIX_go('/')" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-shop-location</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("findabusiness") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isSwanseaResident()">
                <v-btn @click="MIX_go('/offers')" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-sale-price-tag</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("latestoffers") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isSwanseaResident()">
                <v-btn @click="MIX_go('/my-offers')" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-sale-price-tag</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("myoffers") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isSwanseaResident()">
                <v-btn @click="MIX_go('/parking')" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-parking</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("parking") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn @click="MIX_go('/dining-out')" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-cutlery</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("diningout") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn @click="MIX_go('/whats-on')" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-calendar-17</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("whatson") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn href="https://mi-rewards.com" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-quality</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("loyaltycard") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn @click="MIX_go('/defibrillators')" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-heart-with-pulse</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("defibs") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5">
                <v-btn href="https://townandcitygiftcards.com/product/swansea-gift-card/" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-gift-card</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("giftcard") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isAdmin()">
                <v-btn @click="switchtoAdmin()" class="text-body-2 font-weight-bold accent white--text rounded-of menubutton flexcol removetexttransform" block depressed x-large
                    ><v-icon class="pb-2 white--text" x-large>icons8-analytics</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("admin") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="isAdmin()">
                <!-- SPACER -->
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="GET_authenticated">
                <v-btn @click="logout()" class="primary text-body-2 font-weight-bold white--text removetexttransform" block x-large depressed
                    ><v-icon class="pb-2 white--text" x-large>icons8-logout</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("logout") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="!GET_authenticated">
                <v-btn to="/login" class="primary text-body-2 font-weight-bold white--text removetexttransform" block x-large depressed
                    ><v-icon class="pb-2 white--text" x-large>icons8-logout</v-icon>
                    <div class="pb-2 full-width text-center">{{ $t("login") }}</div></v-btn
                >
            </v-col>
            <v-col cols="6" v-if="!GET_authenticated">
                <!-- SPACER -->
            </v-col>
            <v-col cols="6" class="text-center px-5" v-if="GET_currentUserProfile">
                <ProfileDialog :userProfile="GET_currentUserProfile" :dialogTitle="$t('Myprofile')" />
            </v-col>
            <v-col cols="12" class="text-center" style="margin-top: -2em;">
                <div class="caption white--text text--darken-3 full-width text-center mt-2">{{ $t("version") }} {{ appVersion }}</div>
            </v-col>
        </v-row>
        
    </v-navigation-drawer>
</template>

<script>
//import { version } from "../../package.json";
import { mapGetters, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters({
            GET_showDrawer: "GET_showDrawer",
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        appVersion() {
            const version = require('../../../package.json').version
            return version;
        },
        computedViewportheight() {
            return window.innerHeight;
        },
    },
    data: () => ({
        showDrawer: false,
        items: [
            { title: "Home", icon: "mdi-view-dashboard" },
            { title: "About", icon: "mdi-forum" },
        ],
    }),
    methods: {
        ...mapActions({
            ACT_showDrawer: "ACT_showDrawer",
            ACT_authenticated: "ACT_authenticated",
            ACT_isAdmin: "ACT_isAdmin",
        }),
        async logout() {
            // this.ACT_authenticated(false);
            // this.ACT_isAdmin(false);
            // this.$router.push("/");
            await localStorage.removeItem('userToken');
            await localStorage.removeItem('vuex');
            await this.FIREBASE_logout();
            this.$router.push("/");
            location.reload();
        },
        updateShowDrawer() {
            this.ACT_showDrawer(!this.GET_showDrawer);
        },
        switchtoAdmin() {
            this.ACT_authenticated(false);
            this.ACT_isAdmin(true);
            this.$router.push("/admin/dashboard");
        },
        isAdmin(){
            if(this.GET_currentUserProfile){
                if(this.GET_currentUserProfile.level === 'shopadmin' || this.GET_currentUserProfile.level === 'superadmin'){
                    return true;
                }
            }else{
                return false;
            }
        },
        isSwanseaResident(){
            if(this.GET_currentUserProfile){
                const regex = /^(SA1 |SA2 |SA3 |SA4 |SA5 |SA6 |SA7 |SA8 |SA18|SA99)/;
                return regex.test(this.GET_currentUserProfile.postcode);
            }else if(localStorage.userToken){
                return true;
            }else{
                return false;
            }
        },
    },
    components:{
        ProfileDialog: () => import("@/components/card/ProfileDialog.vue"),
    },
    watch: {
        GET_showDrawer: function () {
            this.showDrawer = this.GET_showDrawer;
            //console.log("CLOSED");
            document.getElementById("bottomnav").style.display="none"
        },
        showDrawer: function () {
            this.ACT_showDrawer(this.showDrawer);
            //console.log("VISIBLE");
            document.getElementById("bottomnav").style.display="block"
        },
    },
    created() {
        this.showDrawer = this.GET_showDrawer;
    },
};
</script>

<style>
/* .v-btn__content {
background-color: black;
} */
</style>

<style scoped>
.menubutton {
    min-height: 20px !important;
    height: 80px !important;
}
.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 20px;
}
.menupanel{
    padding: 1em !important;
    max-height: none !important;
    display: none;
}
</style>

<style>
/* fix for scroll behaviour */
.menupanel .row{
    margin: 0 !important
}
</style>