<template>
    <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field dense filled rounded v-model="computedDate" class="rounded-lg" :background-color="background" :label="label" hide-details="auto" append-icon="icons8-timeline-week" readonly v-bind="attrs" v-on="on"> </v-text-field>
            <!-- v-model="innerValue" -->
        </template>
        <v-date-picker :min="min" v-model="date1" no-title @input="dateMenu = false"></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        background: {
            type: String,
            default: "grey lighten-4",
        },
        min: {
            type: String,
            default: "",
        },
    },
    data: (vm)=> ({
        innerValue: "",
        dateMenu: false,
        dataGrabbed: false,
        date1: vm.MIX_formatDateTimeNow("YYYY-MM-DD"),
    }),
    computed: {
        // Computed Date
        computedDate() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
    },
    watch: {
        // Dates
        dateMenu() {
            this.innerValue = this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "unix")
        },
        // Update Parent v-model
        innerValue: function (value) {
            this.$emit("input", value);
        },
        value: function (value) {
          //1697238000
          // console.log(value)
          // console.log(value.toString().length)
          if(!this.dataGrabbed && value.toString().length !== 10){
            this.date1 = this.MIX_formatDateTime(value, 'YYYY-MM-DDTHH:mm:ssZ[Z]', 'YYYY-MM-DD')
            this.dataGrabbed = true
          }

        },

    },
    mounted() {
        this.innerValue = this.value;
    },
};
</script>
