<template>
    <div class="paddingmobile">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/admin/dashboard')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <div class="pl-10 pr-10">
            <v-row>
                <v-col cols="6" class="d-flex align-center">
                    <div class="title font-weight-black primary--text">{{ $t("social") }}</div>
                    <!-- <EditPost :datadown="newsletterDatadown" v-on:edited="initialise()" class="pl-6"/> -->
                    <!-- <AddNewsletterPost class="pl-7" v-on:addedSocial="initialise()"/> -->
                </v-col>
                <!-- <v-col cols="6" class="d-flex justify-end pr-0">
                    <AddSocialPost v-on:addedSocial="initialise()"/>
                </v-col> -->
            </v-row>
            <!-- PAGE CONTENT -->
            <v-row class="">
                <v-col cols="12" xs="12" sm="7" class="" :class="$vuetify.breakpoint.width <=700 ? 'pb-10' : ''">
                    <v-row class="lightgrey rounded-of">
                        <v-col cols="12" class="d-flex align-center pt-5">
                            <!-- <v-img lazy-src='@/assets/SwanseaBid.jpg' max-height="100%" max-width="60px" class="mr-3" style="border-radius:3em;"
                            src='@/assets/SwanseaBid.jpg'></v-img> -->
                            <v-col cols="6">
                                <div class="title pr-4 removetexttransform"><b>{{ $t("swanseanewsletter") }}</b></div>
                            </v-col>
                            <v-col cols="6" class="d-flex align-center justify-end" :style="$i18n.locale === 'cy' ? 'margin-left: -8em !important;' : ''" v-if="GET_currentUserProfile.level === 'superadmin'">
                                <AddNewsletterPost class="" v-on:addedSocial="initialise" />
                            </v-col>
                        </v-col>
                        <v-col cols="12" class="mb-5" style="height: 65vh; overflow:scroll;">
                            <!-- <div class="subtitle-2 removetexttransform paragraphtext newslettercontent" v-html="newsletter"></div> -->
                            <v-col cols="12" xs="12" sm="12" v-for="(post, index) in newsletters" :key="index" class="">
                                <SwanseaBidNewsletterFeed :datadown="post" class="white rounded-of mb-8"
                                    v-on:edited="initialise" v-on:deleted="initialise" />
                            </v-col>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" xs="12" sm="5" class="" :class="$vuetify.breakpoint.width <=700 ? '' : 'pl-8'">
                    <v-row class="lightgrey rounded-of">
                        <v-col cols="12" class="d-flex align-center pt-5 pb-6">
                            <!-- <v-img lazy-src='@/assets/SwanseaBid.jpg' max-height="100%" max-width="60px" class="mr-3" style="border-radius:3em;"
                            src='@/assets/SwanseaBid.jpg'></v-img> -->
                            <v-col cols="6">
                                <div class="title pr-4 removetexttransform"><b>{{ $t("swanseanewsfeed") }}</b></div>
                            </v-col>
                            <v-col cols="6" class="d-flex align-center justify-end" :style="$i18n.locale === 'cy' ? 'margin-left: -5em !important;' : ''" v-if="GET_currentUserProfile.level === 'superadmin'">
                                <AddSocialPost v-on:addedSocial="initialise" />
                            </v-col>
                        </v-col>
                        <v-col cols="12" class="mb-5" style="height: 64vh; overflow:scroll;">
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" v-for="(post, index) in posts" :key="index" class="">
                                    <SwanseaBidNewsfeed :datadown="post" class="white rounded-of mb-8"
                                        v-on:edited="initialise" v-on:deleted="initialise" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "AdminSocialView",
    data: () => ({
        search: "",
        newsletters: [],
        posts: [],
        newsletterDatadown: {
            entityId: "01GV5FJTRG1A1G9YCTJQD5Y4TV",
        },
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
    }),
    components: {
        SwanseaBidNewsfeed: () => import("@/components/card/SwanseaBidNewsfeed.vue"),
        AddSocialPost: () => import("@/components/AddSocialPost.vue"),
        // EditPost: () => import("@/components/EditPost.vue"),
        AddNewsletterPost: () => import("@/components/AddNewsletterPost.vue"),
        SwanseaBidNewsletterFeed: () => import("@/components/card/SwanseaBidNewsletterFeed.vue"),
    },
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
    },
    methods: {
        async initialise() {
            this.loading = true;
            await this.getSocial();
            await this.getNewsletter();
            this.loading = false;
            //console.log(JSON.stringify(this.offers, null, 2))
            //console.log(this.stores[1].name)
        },
        async getSocial() {
            return new Promise((resolve, reject) => {
                //REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query) {
                this.REDIS_searchFor("social", "", "", "createdDateTime", "desc", ` @postTitle:Newsfeed @deleted:{false}`)
                    .then((result) => {
                        this.posts = result.data.documents;
                        resolve(result);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async getNewsletter() {
            // Newsletter is under the Social collection, ID: 01GV5FJTRG1A1G9YCTJQD5Y4TV
            return new Promise((resolve, reject) => {
                //REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query) {
                // REDIS_read(collection, entityId)
                this.REDIS_searchFor("social", "", "", "createdDateTime", "desc", ` @postTitle:Newsletter @deleted:{false}`)
                    .then((result) => {
                        this.newsletters = result.data.documents;
                        resolve(result);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
    created() {
        this.initialise();
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.paragraphtext {
    line-height: 1.4rem !important;
}

.newslettercontent p {
    margin-bottom: 0px !important;
}

.newslettercontent strong {
    color: #cc0200 !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
}

.newslettercontent em {
    color: #cc0200 !important;
    margin-bottom: 0px !important;
}

/* This moves the newsletter add button over so it aligns with the table on desktop  */
.addbutton {
    padding-left: 130px !important;
}

/* This moves the newsletter add button over so it aligns with the table on mobile  */
@media all and (min-width:0px) and (max-width: 480px) {
    .addbutton {
        padding-left: 30px !important;
    }
}

/* This moves the newsfeed add button over so it aligns with the table on desktop  */
.addbuttonfeed {
    padding-left: 50px !important;
}

/* This moves the newsfeed add button over so it aligns with the table on mobile  */
@media all and (min-width:0px) and (max-width: 480px) {
    .addbuttonfeed {
        padding-left: 50px !important;
    }
}

/* This adds left padding to the newsfeed col on desktop  */
.newsfeedcol {
    padding-left: 30px !important;
}

/* This reduces left padding to the newsfeed col on desktop  */
@media all and (min-width:0px) and (max-width: 480px) {
    .newsfeedcol {
        padding-left: 10px !important;
        margin-top: 20px !important;
    }
}</style>
