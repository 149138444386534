<template>
    <v-banner app class="primary full-width fixed foreground-highest elevation-0 pa-0 ma-0 bottom-border" height="70">
        <div class="d-flex align-center justify-center">
            <div class="d-flex align-center">
                <div class="text-h7 white--text font-weight-bold pr-6">Install App?</div>
                <v-btn @click='dialog = true' depressed class="animate__animated animate__pulse animate__infinite white--text accent secondary--text animated"><v-icon class="mr-2">icons8-download-from-cloud</v-icon>Install</v-btn>
            </div>
            <v-spacer />
            <v-btn @click="$emit('dismiss')" icon><v-icon class="white--text">icons8-close</v-icon></v-btn>
        </div>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card class="white pa-7 rounded-of">
                <v-row class="white">
                    <v-col cols="12">
                        <div class="title black--text text-center">Install App</div>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-column align-center justify-center">
                        <div class="subtitle-1 black--text">1. Tap this icon at the bottom of your screen:</div>
                        <v-icon class="primary--text pa-1" large>icons8-share</v-icon>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-column align-center justify-center pt-0 pb-6">
                        <div class="subtitle-1 black--text">2. Select "Add to Home Screen"</div>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="subtitle-1 px-6 primary rounded-of removetexttransform" depressed @click="closeInstall">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-banner>
</template>
<script>
export default {
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        refresh() {
            window.location.reload();
        },
        closeInstall(){
            this.dialog = false
            this.$emit('dismiss')
        }
    },
};
</script>
